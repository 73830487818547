import { lazy } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

// project import
import Loadable from 'components/Loadable';
import { ContactsContextProvider as ContactsProvider } from 'contexts/ContactsContext';

const CompaniesTable = Loadable(lazy(() => import('pages/contacts/companiesTable')));
const ContractCarsTable = Loadable(lazy(() => import('pages/contracts/contractsCarTable')));
const ContractProfile = Loadable(lazy(() => import('pages/contracts/contractProfile')));
const TabStock = Loadable(lazy(() => import('sections/apps/profiles/contract/TabStock')));
const TabHistoric = Loadable(lazy(() => import('sections/apps/profiles/contract/TabHistoric')));
const TabDetails = Loadable(lazy(() => import('sections/apps/profiles/contract/TabDetails')));
const TabActivity = Loadable(lazy(() => import('sections/apps/profiles/contract/TabActivity')));
const TabInvoices = Loadable(lazy(() => import('sections/apps/profiles/contract/TabInvoices')));
const InvoiceProfile = Loadable(lazy(() => import('pages/invoices/invoiceProfile')));
const InvoiceEdit = Loadable(lazy(() => import('pages/invoices/invoiceEdit')));
const DemandeCarsTable = Loadable(lazy(() => import('pages/cars/demandeCarsTable')));
const RequestCarsTable = Loadable(lazy(() => import('pages/cars/requestCarsTable')));
const InventoryCarsTable = Loadable(lazy(() => import('pages/cars/inventoryCarsTable')));
const PreparationCarsTable = Loadable(lazy(() => import('pages/cars/preparationCarsTable')));
const LitigeCarsTable = Loadable(lazy(() => import('pages/cars/litigeCarsTable')));
const CarSourcingContactsTable = Loadable(lazy(() => import('pages/contacts/carSourcingContactsTable')));
const CompanyProfile = Loadable(lazy(() => import('pages/contacts/company')));
const CarProfile = Loadable(lazy(() => import('pages/cars/carProfile')));
const TabCar = Loadable(lazy(() => import('sections/apps/profiles/car/TabCar')));
const TabCarPhotos = Loadable(lazy(() => import('sections/apps/profiles/car/TabCarPhotos')));
const TabCompany = Loadable(lazy(() => import('sections/apps/profiles/company/TabCompany')));
const TabCompanyAnalytics = Loadable(lazy(() => import('sections/apps/profiles/company/TabCompanyAnalytics')));
const TabCompanyContacts = Loadable(lazy(() => import('sections/apps/profiles/company/TabCompanyContacts')));
const FinanceTable = Loadable(lazy(() => import('pages/cars/financeTable')));

// ==============================|| APPS ROUTING ||============================== //

const ContactsRoutes = {
  path: '/',
  element: (
    <ContactsProvider>
      <Outlet />
    </ContactsProvider>
  ),
  children: [
    {
      path: '/contracts',
      element: <ContractCarsTable />
    },
    {
      path: '/contracts/:id',
      element: <ContractProfile />,
      children: [
        {
          path: '',
          element: <Navigate to="details" />
        },
        {
          path: 'details',
          element: <TabDetails />
        },
        {
          path: 'stock',
          element: <TabStock />
        },
        {
          path: 'activity',
          element: <TabActivity />
        },
        {
          path: 'invoices',
          element: <TabInvoices />
        },
        {
          path: 'historic',
          element: <TabHistoric />
        }
      ]
    },
    {
      path: '/contracts',
      element: <ContractCarsTable />
    },
    {
      path: '/invoices',
      element: <FinanceTable />
    },
    {
      path: '/invoices/:id',
      children: [
        {
          path: 'details',
          element: <InvoiceProfile />
        },
        {
          path: 'edit',
          element: <InvoiceEdit />
        }
      ]
    },
    {
      path: '/demandes',
      element: <DemandeCarsTable />
    },
    {
      path: '/demandes/:id',
      element: <CarProfile />,
      children: [
        {
          path: '',
          element: <Navigate to="details" />
        },
        {
          path: 'details',
          element: <TabCar />
        },
        {
          path: 'contacts',
          element: <TabCompanyContacts />
        },
        {
          path: 'analytics',
          element: <TabCompanyAnalytics />
        }
      ]
    },
    {
      path: '/commandes',
      element: <RequestCarsTable />
    },
    {
      path: '/commandes/:id',
      element: <CarProfile />,
      children: [
        {
          path: '',
          element: <Navigate to="details" />
        },
        {
          path: 'details',
          element: <TabCar />
        },
        {
          path: 'photos',
          element: <TabCarPhotos />
        },
        {
          path: 'analytics',
          element: <TabCompanyAnalytics />
        }
      ]
    },
    {
      path: '/inventaire',
      element: <InventoryCarsTable />
    },
    {
      path: '/inventaire/:id',
      element: <CarProfile />,
      children: [
        {
          path: '',
          element: <Navigate to="details" />
        },
        {
          path: 'details',
          element: <TabCar />
        },
        {
          path: 'contacts',
          element: <TabCompanyContacts />
        },
        {
          path: 'analytics',
          element: <TabCompanyAnalytics />
        }
      ]
    },
    {
      path: '/clients',
      element: <CompaniesTable />
    },
    {
      path: '/client/:id',
      element: <CompanyProfile />,
      children: [
        {
          path: '',
          element: <Navigate to="details" />
        },
        {
          path: 'details',
          element: <TabCompany />
        },
        {
          path: 'contacts',
          element: <TabCompanyContacts />
        },
        {
          path: 'analytics',
          element: <TabCompanyAnalytics />
        }
      ]
    },
    {
      path: '/planning',
      element: <CarSourcingContactsTable />
    },
    {
      path: '/preparation',
      element: <PreparationCarsTable />
    },
    {
      path: '/preparation/:id',
      element: <CarProfile />,
      children: [
        {
          path: '',
          element: <Navigate to="details" />
        },
        {
          path: 'details',
          element: <TabCar />
        },
        {
          path: 'photos',
          element: <TabCarPhotos />
        },
        {
          path: 'analytics',
          element: <TabCompanyAnalytics />
        }
      ]
    },
    {
      path: '/kpi',
      element: <CarSourcingContactsTable />
    },
    {
      path: '/litige',
      element: <LitigeCarsTable />
    }
  ]
};

export default ContactsRoutes;
